import { useEffect, useState } from "react";
import ErrorLayout from "../layout/error";

const NotFound = () => {
  const [isApp, setIsApp] = useState(false);
  useEffect(() => setIsApp(window.location.pathname.startsWith("/app")));
  return <ErrorLayout title="Page not found" isApp={isApp} className="" />;
};

export default NotFound;
